<template lang="pug">
div
  h2 Unique Icons
  figure.highlighted
    .d-flex.mb-3
      .row
        .col-12
          .d-flex.flex-wrap
            .d-flex.flex-column.justify-content-center.align-items-center.mr-2.mb-2.icon(
              v-for="icon in icons"
              @click="selected = icon"
              :class="selected === icon ? 'selected' : ''"
            )
              om-unique-icon(:key="icon" :name="icon")
              div {{ icon }}
    .d-flex.w-100.row.mx-0.mt-5
      .col-12
        h2.mb-4.ml-2 Playground
      .col-3
        om-select(v-model="size" :options="sizeOptions")
      .col-9
        om-unique-icon(:name="selected" :size="size")
    .d-flex.w-100.row.mx-0.mt-5
      .col-12
        h2.mb-4.ml-2 Usage
      .col-12
        .brand-use-code
          highlight.language-markup(:codeStr="code")
</template>

<script>
  import base from './base';

  const icons = require.context('@/components/Elements/UniqueIcon/svgs', true, /^.*\.svg$/);

  export default {
    mixins: [base],

    data() {
      return {
        selected: '2-column-20-80',
        size: '52',
        sizeOptions: ['100%', 32, 48, 52, 56, 60].map((value) => ({
          key: value,
          value: `${value}`,
        })),
        icons: icons.keys().map((filename) => filename.substring(2).replace('.svg', '')),
      };
    },

    computed: {
      code() {
        return `om-unique-icon(name="${this.selected}" size="${this.size}")`;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .icon
    flex: 0 0 16%
    height: 100px
    cursor: pointer

    &.selected
      font-weight: bold
      border: 2px solid #ED5A29
      border-radius: 4px
      background: #fff
</style>
